import ChartHorizontalBar from '@/components/ChartHorizontalBar';

export default {
  name: 'ChartHorizontalBarWithStep',

  components: {
    ChartHorizontalBar,
  },

  props: {
    dataSet: {
      required: true,
      type: Array,
    },

    steps: {
      default: 9,
    },

    inline: {
      default: false,
      type: Boolean,
    },

    itemClass: {
      default: null,
      type: String,
      required: false,
    },
  },

  computed: {
    maxValue() {
      const data = (this.dataSet || []).filter(Boolean).map(item => item.data);

      return Math.max(...data) ?? 0;
    },

    pointSteps() {
      let points = [0];

      if (this.maxValue >= this.steps) {
        const step = parseFloat(this.maxValue / this.steps).toFixed(0);
        let sum = 0;

        while (sum <= this.maxValue - step) {
          sum += parseFloat(step);
          points.push(this.getStringSize(sum));
        }
      }

      points.push(this.getStringSize(this.maxValue));

      return points;
    },
  },

  methods: {
    getStringSize(size) {
      let val = size;
      let id = '';

      if (size > 999) {
        val = size / 1000;
        id = 'K';
      }

      if (size > 999999) {
        val = size / 1000000;
        id = 'M';
      }

      return `${parseInt(val)} ${id}`;
    },
  },
};
