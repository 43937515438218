export default {
  name: 'ChartHorizontalBar',

  props: {
    clearStyle: {
      default: false,
      type: Boolean,
    },
    dataSet: {
      required: true,
      type: Array,
    },
    hint: {
      default: false,
      type: Boolean,
    },
    showLabel: {
      default: true,
      type: Boolean,
    },
    showData: {
      default: true,
      type: Boolean,
    },
    inline: {
      default: false,
      type: Boolean,
    },
    itemClass: {
      default: null,
      type: String,
      required: false,
    },
  },

  computed: {
    maxValue() {
      return Math.max(...this.dataSet.map(item => item.data));
    },

    maxCounter() {
      return this.dataSet
        .map(item => item.data)
        .reduce((prev, cv) => {
          return prev + cv;
        }, 0);
    },

    hintStyle() {
      if (this.hint) {
        if (window.innerWidth < 500) {
          return 'hint--top';
        }

        return 'hint--left';
      }
    },
  },

  methods: {
    getPercent(value) {
      const percent = (value * 100) / this.maxCounter;

      return isNaN(percent) ? 0 : parseFloat(percent).toFixed(2);
    },

    getWidthSize(value) {
      const percent = (value * 100) / this.maxValue;

      return isNaN(percent) ? 0 : parseFloat(percent).toFixed(2);
    },

    getHint(item) {
      const { label, data } = item;

      return `${label}: ${this.$filters.formatNumber(data, 0)}`;
    },
  },
};
